import React, { Component } from 'react'

export class Services extends Component {
	render() {
		return (
			<div id="services" className="text-center">
				<div className="container">
					<div className="section-title">
						<h2>Atuação</h2>
						{/*<p>Especialista em Direito Penal Econômico pela Universidade de Coimbra-Portugal, em Ciências Penais pela Universidade Estadual de Maringá e em Direito Constitucional pela Universidade Cândido Mendes.</p>*/}
					</div>
					<div className="row">

						{this.props.data ? this.props.data.map((d, idx) =>
							<div key={idx} className="col-md-4"> <i className={d.icon}></i>
								<div className="service-desc">
									<h3>{d.name}</h3>
									{/*<p>{d.text}</p>*/}
								</div>
							</div>
						): 'carregando'}
					</div>
					<div className="row details">
						{/*<a href="#detalhes" className="btn btn-custom btn-lg page-scroll">Saiba mais</a>*/}
						<div className="col-xs-12 text-justify">
							<h3>Direito Penal/Penal Econômico</h3>
							<ul>
								<li>Crimes contra a vida, integridade física, honra, dignidade sexual, patrimônio, tráfico de drogas, violência doméstica, dentre outros;</li>
								<li>Atuação como assistente de acusação, na defesa dos interesses das vítimas de delitos;</li>
								<li>Tribunal do Júri;</li>
								<li>Corrupção, evasão de divisas, associação criminosa empresarial, crimes ambientais, delação premiada, acordo de leniência, crimes cibernéticos, crimes contra a livre concorrência, crimes licitatórios, violação de direitos humanos no âmbito corporativo, lavagem de dinheiro, insider trading, crimes tributários e previdenciários, crimes contra o Sistema Financeiro Nacional, responsabilidade penal da Pessoa Jurídica, recuperação de ativos, dentre outros.</li>
							</ul>

							<h3>Compliance Criminal</h3>
							<ul>
								<li>Estruturação do programa de compliance, responsabilidade penal dos administradores e do compliance office e Elaboração de cláusulas e revisão de contratos conforme a legislação anticorrupção;</li>
								<li>Assessoria nos processos a serem realizados dentro da empresa a fim de prevenir sanções legais, contando com avaliações periódicas da eficácia do programa de compliance;</li>
								<li>Palestras e treinamentos para colaboradores;</li>
								<li>Assessoria em processos disciplinares internos; Assessoria e representação em acordos de leniência; Representação em processos administrativos e judiciais; Avaliação de riscos criminais e representação de empresas, executivos e empregados em investigações e processos criminais, incluindo a negociação de colaborações premiadas; dentre outros.</li>
							</ul>

							<h3>Direito Constitucional</h3>
							<ul>
								<li>Elaboração de análises, estudos e pareceres legais em temas como ordem econômica, separação de poderes, competências constitucionais de entes políticos, Administração Pública, partidos políticos, orçamento público e direitos fundamentais;</li>
								<li>Writs Constitucionais: Mandado de Segurança (individual e coletivo), mandado de injunção, habeas corpus, habeas datas;</li>
								<li>Defesa dos movimentos sociais e seus representantes na esfera administrativa e judicial, Mandado de Segurança (individual e coletivo) contra atos abusivos e ilegais de autoridades públicas, Assessoria jurídica a movimentos de luta por moradia (urbana e rural) e aos movimentos de luta das minorias, acompanhamento de ações de reintegração de posse; Ações indenizatórias; dentre outros.</li>
							</ul>

							<h3>Contratos e Negociações</h3>
							<ul>
								<li>Redação, revisão e negociação de contratos, nas esferas cível, mercantil, consumerista e administrativa. A assessoria abrange as etapas do contrato de “ponta a ponta”, ou seja, desde a negociação até eventual necessidade de execução por descumprimento. O foco dos serviços é implementar garantias de cumprimento de obrigações, de acordo com as necessidades de cada cliente.</li>
							</ul>

							<h3>Família e Sucessões</h3>
							<ul>
								<li>Representação em processos de divórcio e dissolução de união estável, pensão alimentícia, partilha de bens e guarda de filhos;</li>
								<li>Assessoria jurídica no planejamento sucessório pessoal e empresarial, objetivando preservação do patrimônio familiar e da empresa;</li>
								<li>Planejamento patrimonial e financeiro do casamento e da união estável, por meio da orientação na escolha do regime de bens, elaboração do pacto antenupcial e do contrato social do cônjuge empresário;</li>
								<li>Assessoria jurídica em processos sucessórios: inventário e partilha judicial e extrajudicial, cumprimento de testamento e composição de conflitos entre herdeiros.</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Services
