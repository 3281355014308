import React, { Component } from 'react'

export class Gallery extends Component {
  render() {
    return (
        <div id="footer">
		  <div className="container text-center">
		    <p>&copy; {(new Date()).getFullYear()} Trindade de Moraes Advocacia</p>
		  </div>
		</div>
    )
  }
}

export default Gallery
