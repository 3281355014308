import React, { Component } from 'react'

export class Gallery extends Component {
	render() {
		return (
			<div>
				<div id="visao">
					<div className="container">
						<div className="section-title text-center">
							<h2>Institucional</h2>
						</div>
						<div className="row">
							<div className="hidden-xs visible-lg-block col-lg-4">
								<div className="logo-wrapper">
									<img className="logo" src="/img/logo-dark.png" alt="Logo" />
								</div>
							</div>
							<div className="col-xs-12 col-lg-8">
								<div className="text-justify">
									<p>
										O escritório Trindade de Moraes Advocacia é liderado pelo advogado Dr. Alan Trindade de Moraes, profissional com <b>atuação destacada na região de Maringá-PR, além do reconhecimento à nível nacional por sua atuação em outros estados do Brasil</b>. Nesse caminho, mantendo o assíduo compromisso com os clientes do escritório localizado na bela "Cidade Canção", expandiu suas relações sendo associado do Oliveira, Rocha & Rezende Advogados, escritório sediado na capital paranaense, Curitiba.
										<br/><br/>
										Ressalta-se que a Advocacia Criminal é um instrumento de proteção da liberdade do cidadão contra as arbitrariedades do Estado e também um mecanismo de assistência às vítimas de crimes. Assim, o escritório não trata somente de práticas para a detecção e redução de riscos, mas também do acolhimento das pessoas e de seus familiares que se veem envolvidos em fatos de repercussão penal e que precisam de <b>especialista para dividir e resolver seus problemas com ética e privacidade</b>.
										<br/><br/>
										Salienta-se que o escritório foi pensado e estruturado sob o modelo europeu, chamado hoje em dia de Boutique Jurídica. Por conseguinte, <b>nos organizamos para sermos altamente especializados para atender demandas de maior complexidade, com atendimento personalizado e exclusivo</b>, no qual o cliente tem contato direto com o advogado que comanda o escritório, militante e especialista que está presente em todas as etapas do processo, garantindo segurança, qualidade e eficiência.
										<br/><br/>
										Com essa estruturação, buscamos exercer a <b>Advocacia Criminal de forma artesanal</b>, ou seja, àquela mais distante da defesa padronizada que apenas postula a absolvição “por justiça” ou por ausência de provas. Desta forma, <b>nosso compromisso é de ir além, com estudo minucioso do caso, abordando inúmeras outras questões técnicas e estratégicas importantes</b> como nulidades, causas extintivas da punibilidade, desclassificação, afastamento das qualificadoras/agravantes/majorantes, acolhimento das privilegiadoras/atenuantes/minorantes, impugnação do regime prisional aplicado, combatividade em relação às frações – de aumento ou diminuição de pena – aplicadas etc.
										<br/><br/>
										No desempenho dessa <b>profissão-missão</b> que é a Advocacia, atuamos com <b>ênfase para o atendimento de pessoas jurídicas e físicas</b> em <b>demandas envolvendo a sub-área de Direito Penal Econômico e Empresarial</b>, em causas envolvendo delitos financeiros, lavagem de capitais, crimes contra a ordem tributária, crimes ambientais, crimes contra as relações de consumo, entre outros, inclusive em questões de compliance, de corporate law, de avaliação de passivos criminais existentes por ocasião de operações de M&A e em investigações internas de delitos cometidos por seus colaboradores e fornecedores.
										<br/><br/>
										<b>Com relação ao chamado Direito Penal clássico</b>, atua tanto no <b>Tribunal do Júri</b> como no acompanhamento de <b>inquéritos policiais e ações penais, das mais simples até as mais complexas, em todas as instâncias</b>. Além da atuação na fase instrutória, tem experiência na atuação em fase de execução penal.
										<br/><br/>
										Ademais, buscando melhores resultados na estruturação de <b>caminhos para prevenir conflitos nas relações empresariais e, não obstante, familiares de seus clientes</b>, oferecemos também serviços nas seguintes áreas do Direito: <b>COMPLIANCE, CONSTITUCIONAL, CONTRATOS E NEGOCIAÇÕES e FAMÍLIA E SUCESSÕES</b>.
									</p>
								</div>
							</div>
							<div className="col-xs-12">
								<div className="container selos">
									<div className="row">
										<div className="col-xs-12 text-center mb-1 selo-wrapper">
											<a href="https://www.instagram.com/p/CmiCKocpXw1/" target="_blank" rel="noopener noreferrer">
												<img src="/img/selos.png" alt="Selos ANCEC e Privilege Pesquisas" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Gallery
