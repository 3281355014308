import React, { Component } from 'react'

export class Navigation extends Component {

	render() {
		return (
			<nav id="menu" className="navbar navbar-default navbar-fixed-top">
				<div className="container">
					<div className="navbar-header">
						<button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1"> <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span> <span className="icon-bar"></span> <span className="icon-bar"></span> </button>
						<a className="navbar-brand page-scroll" href="#page-top">
							<img src="/img/logo-nav.png" alt="Slogan do Trindade de Moraes Advocacia" title="Trindade de Moraes Advocacia" />
						</a>
					</div>

					<div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
						<ul className="nav navbar-nav navbar-right">
							{/*<li><a href="#features" className="page-scroll">Features</a></li>*/}
							<li><a href="#visao" className="page-scroll">Institucional</a></li>
							<li><a href="#services" className="page-scroll">Atuação</a></li>
							{/*<li><a href="#portfolio" className="page-scroll">Galeria</a></li>*/}
							<li><a href="#about" className="page-scroll">Profissional</a></li>
							<li><a href="#contact" className="page-scroll">Contato</a></li>
							<li><a href="#testimonials" className="page-scroll">Avaliações</a></li>
							{/*<li><a href="#team" className="page-scroll">Team</a></li>*/}
						</ul>
					</div>
				</div>
			</nav>
		)
	}
}

export default Navigation
