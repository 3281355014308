import React, { Component } from 'react'

export class Header extends Component {
	render() {
		return (
			<header id="header">
				<div className="intro">
					<div className="intro-bg"/>
					<div className="intro-logo-wrapper">
						<img src="/img/logo-light.png" alt="Logo" />
						<a href="#contact" className="btn btn-custom btn-lg page-scroll">Entre em contato</a>
						<i className="fa fa-angle-double-down scroll-hint"></i>
					</div>
				</div>
			</header>
		)
	}
}

export default Header
