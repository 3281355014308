import React, { Component } from 'react'
import Navigation from './navigation';
import Header from './header';
// import Features from './features';
import About from './about';
import Services from './services';
// import Gallery from './gallery';
import Testimonials from './testimonials';
// import Team from './Team';
import Contact from './contact';
import Visao from './Visao';
import Footer from './Footer';
import $ from 'jquery';

export class App extends Component {
  state = {
    resumeData : {},
  }
  getResumeData(){
    $.ajax({
      url:'/data.json',
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({resumeData: data});
      }.bind(this),
      error: function(xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount(){
    this.getResumeData();
  }

  render() {
    return (
      <div>
        <Navigation />
        <Header data={this.state.resumeData.Header}/>
        {/*<Features data={this.state.resumeData.Features}/>*/}
        <Visao/>
        <Services data={this.state.resumeData.Services}/>
        <About data={this.state.resumeData.About}/>
        {/*<Gallery />*/}
        <Contact data={this.state.resumeData.Contact}/>
        <Testimonials data={this.state.resumeData.Testimonials}/>
        {/*<Team data={this.state.resumeData.Team}/>*/}
        <Footer data={this.state.resumeData.Footer}/>
      </div>
    )
  }
}

export default App
