import React, { Component } from 'react'

export class Contact extends Component {
	static propTypes = {
	}

	render() {
		return (
			<div>
				<div id="contact">
					<div className="container">
						<div className="col-md-12">
							<div className="row">
								<div className="section-title text-center">
									<h2>Contato</h2>
								</div>
							</div>
						</div>
						<div className="col-md-12 contact-info text-center">
							<div className="contact-item">
								{/*<h3>Informações de contato</h3>*/}
								<p>
									<span><i className="fa fa-map-marker"></i> Endereço</span>
								</p>
								<p>
									<a href="https://g.page/criminalistaemmaringa" target="_blank" rel="noopener noreferrer">Av. Dom Manoel da Silveira D'Elboux, 1225, Sala 03, Zona 05, Maringá-PR, CEP: 87015-320.</a>
								</p>
							</div>
							<div className="contact-item">
								<p>
									<span><i className="fa fa-phone"></i> Telefone</span>
								</p>
								<p>
									<a href="tel:(44) 99134-8753">(44) 99134-8753</a>
								</p>
							</div>
							<div className="contact-item">
								<p>
									<span><i className="fa fa-envelope-o"></i> Email</span>
								</p>
								<p>
									<span><a href="mailto:contato@trindademoraes.adv.br">contato@trindademoraes.adv.br</a></span>
								</p>
							</div>
						</div>
						<div className="col-md-12">
							<div className="row">
								<div className="social">
									<ul>
										<li><a href="https://www.instagram.com/trindademoraes.adv" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
										<li><a href="https://g.page/criminalistaemmaringa" target="_blank" rel="noopener noreferrer"><i className="fa fa-map-marker"></i></a></li>
										<li><a href="https://www.linkedin.com/in/trindademoraesadv" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li>
										<li><a href="https://wa.me/5544991348753" target="_blank" rel="noopener noreferrer"><i className="fa fa-whatsapp"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Contact
